import React from "react";
import Layout from "../components/Layout";
import UsedTechnology from '../components/demo/UsedTechnology/UsedTechnology'
// import Carousel from '../components/Carousel'
import { Helmet } from "react-helmet";
import MyImage from "../components/demo/myImage/MyImage";

const AboutUs = () => {
  return (
    <>
      <Layout>
        <Helmet>
          <title>Бидний тухай</title>
        </Helmet>
        <div className="pt-[150px] max-md:pt-[50px]">
        <div className='grid grid-cols-1 max-md:grid-cols-1 gap-10 container max-w-[800px] '>
          <div  data-aos="zoom-in-up">
          <h2 className="font-bold text-3xl text-center max-md:text-lg dark:text-[rgba(255,255,255,.9)]">Бидний тухай</h2>
        <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto my-3 max-lg:w-24 "></div>
          <div className=" my-5 max-md:font-normal max-md:text-[10px] text-center dark:text-[rgba(255,255,255,.9)] ">
          Бидэнтэй   хамтран ажилласнаар  байгууллагадаа   бизнес   процесс   менежментийг
          хэрэгжүүлснээр дэвшилтэт технологийн тусламжтайгаар сайжруулах,
          өөрчлөх,   автоматжуулах,   удирдах   замаар   харилцагч   болон   ажилтан
          төвтэй бизнесийн бүтэн процессуудыг бий болгох боломжтой.
          </div>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-[100px] max-md:mt-[30px] container gap-[70px] max-lg:gap-[20px] my-10">
            <div data-aos="zoom-in-up" className="border-2 flex flex-col rounded-[15px] text-center  dark:border-[#1AAB96] dark:bg-[rgba(255,255,255,.1)]">
              <div>
              <img
                src="/images/mission.png"
                alt="mission"
                className="max-h-[80px] max-md:max-h-[20px] mx-auto"
              />
              <h2 className="font-bold text-2xl italic dark:text-white max-md:text-sm">Mission</h2>
              </div>
              <div className="dark:text-[rgba(255,255,255)] px-3 max-md:text-[8px] h-full flex items-center">
                Процесс суурьтай соёлыг түгээгч,
                дижитал шилжилтийг дэмжигч компани
              </div>
            </div>
            <div data-aos="zoom-in-up" className="border-2 flex flex-col rounded-[15px] text-center  dark:border-[#1AAB96] dark:bg-[rgba(255,255,255,.1)]">
              <div>
              <img
                src="/images/Vision.png"
                alt="vission"
                className="max-h-[80px] max-md:max-h-[20px] mx-auto"

              />
              <h2 className="font-bold text-2xl italic dark:text-white max-md:text-sm">Vision</h2>
              </div>
              <div className="dark:text-[rgba(255,255,255)] px-3 max-md:text-[8px] h-full flex items-center">
              Ухаалаг бизнес процесс менежментийг нэвтрүүлэгч олон улсын стандартыг таниулагч компани
              </div>
            </div>
            <div data-aos="zoom-in-up" className="border-2 flex flex-col rounded-[15px] text-center  dark:border-[#1AAB96] dark:bg-[rgba(255,255,255,.1)]">
              <div>
              <img
                src="/images/Values.png"
                alt="value"
                className="max-h-[80px] max-md:max-h-[20px] mx-auto"
              />
              <h2 className="font-bold text-2xl italic dark:text-white max-md:text-sm">Value</h2>
              </div>
              <div className="dark:text-[rgba(255,255,255)] px-3 max-md:text-[8px] h-full flex items-center">
              Мэргэшсэн чадварлаг боловсон хүчин Дэвшилтэт технологи дээр суурилсан шийдэл Үндэсний компаниудын хөгжлийг дэмжигч
              </div>
            </div>
          </div>
        {/* <div className=" max-md:my-[50px] bg-[rgba(0,0,0,.03)] py-[50px]  dark:bg-[rgba(255,255,255,.1)]">
          <Carousel/>
        </div> */}
        <div className="py-[100px] max-md:py-[30px] dark:bg-[rgba(0,0,0)]">
          <MyImage/>
          </div>
          <div className="py-[100px] max-lg:py-[50px]">
            <div className="pb-[80px] max-md:pb-[30px]">
            <h2 className="text-2xl font-semibold text-center max-md:text-lg dark:text-[#fff]">Ашигладаг Технологиуд</h2>
        <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto my-3 max-lg:w-24 "></div>
        </div>
            <UsedTechnology/>
          </div>
          </div>
      </Layout>
    </>
  );
};

export default AboutUs;
