import styles from "./Sponser.module.css";

export default function Sponser() { 
  const data = [
    {title:"Стратеги",description:"Удирдагч, ахлах, захирал оролцохгүй л бол ажил явахгүй, гүйцэтгэл муу байх байдлыг арилгана",img:"/images/sponser/6.jpg"},
    {title:"Стратеги",description:"Эмх замбараагүй олон үйл ажиллагаа, хэн ямар ажил хийх нь тодорхойгүй, дундаа хаясан байдлыг сайжруулна",img:"/images/sponser/4.jpg"},
    {title:"Стратеги",description:"Үйл ажиллагаанд гарч буй алдаа дутагдлуудыг арилгана",img:"/images/sponser/3.jpg"},
    {title:"Стратеги",description:"Бизнесийн   процессыг   бүтнээр   нь   харж   чадахгүй   зөвхөн   өөрсдийн хариуцсан үйл ажиллагааг харах байдлыг сайжруулна",img:"/images/sponser/2.jpg"},
    {title:"Стратеги",description:"Гүйцэтгэлээ бодит хэмжиж чадахгүй байх асуудлыг шийдвэрлэнэ",img:"/images/sponser/1.jpg"},
    {title:"Стратеги",description:"Харилцагчийн туршлага, сэтгэл ханамжийг дээшлүүлнэ",img:"/images/sponser/5.jpg"},
    {title:"Стратеги",description:"Гар ажиллагаа ихтэй, ажилтнаас хэт их цаг шаарддаг ажлуудыг автоматжуулна",img:"/images/sponser/8.jpg"},
    {title:"Стратеги",description:"Байгууллагын үйл ажиллагаа, бүтээмж, үр ашигтай холбоотой бусад асуудлуудыг шийдвэрлэнэ",img:"/images/sponser/7.jpg"}
  ]
  return (
    <>
      <div className="text-center font-semibold text-2xl text-[#153245]  mb-[100px] max-md:mt-[50px] max-md:mb-[30px] max-md:text-lg dark:text-[#fff]">
        БИД ЯМАР АСУУДЛЫГ ШИЙДВЭРЛЭХ ВЭ ?
        <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto my-3 max-lg:w-24 "></div>
      </div>

      <div className={styles.body}>
        <div className={styles.marquee}>
          <div className={styles.marquee__group}>
            {data.map((item,index)=>(
              <div key={index} className="bg-[rgba(0,0,0,.03)] border grid grid-cols-2 items-center rounded-lg overflow-hidden gap-2 w-[500px] h-[200px] max-md:w-[300px] max-md:h-[100px] dark:bg-[rgba(255,255,255,.07)]">
              <img
                src={item.img}
                alt=""
                className="w-full h-[200px] max-md:h-[100px]  rounded-lg  object-cover"
              />
              <div>
              <div className="text-[16px] max-md:text-[10px] mr-3 dark:text-[#fff] text-center ">
                {item.description}
              </div>
              </div>
            </div>
            ))}
          </div>

          <div
            aria-hidden="true"
            className={styles.marquee__group}
          >
             {data.map((item,index)=>(
              <div key={index} className="bg-[rgba(0,0,0,.03)] border grid grid-cols-2 items-center rounded-lg overflow-hidden gap-2 w-[500px] h-[200px] max-md:w-[300px] max-md:h-[100px] dark:bg-[rgba(255,255,255,.07)]">
              <img
                src={item.img}
                alt=""
                className="w-full h-[200px] max-md:h-[100px] rounded-lg object-cover"
              />
              <div>
              <div className="text-[16px] max-md:text-[10px] mr-3 dark:text-[#fff] text-center">
                {item.description}
              </div>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
