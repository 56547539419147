import React from 'react'
import styles from "./Reason.module.css"
import { FaPeopleGroup,FaRobot,FaClipboardCheck,FaRepeat } from "react-icons/fa6";
import {TbSettingsCode} from 'react-icons/tb'
const Reason = () => {
    const data = [
        {paragraph:'Мэргэжлийн өндөр түвшинд ажиллах баг хамт олон',icon:FaPeopleGroup},
        {paragraph:'Процессын удирдлагыг хэрхэн үр дүнтэй хийх, байгууллагын процесс тасралтгүй сайжирдаг байх сургалт, зөвлөгөөгөөр хангах',icon:FaRepeat},
        {paragraph:'Процессын   бодит   өөрчлөлт,   сайжруулалт,   дахин   загварчлал   болон автоматжуулалтыг нэвтрүүлэх, жигдрүүлэх',icon:FaRobot},
        {paragraph:'Дахин загварчилж сайжруулсан процесст тохирох систем технологийн шийдлийг санал болгох, хөгжүүлэх, нэвтрүүлэх',icon:TbSettingsCode},
        {paragraph:'Урьдчилсан болон нэвтрүүлэлтийн дараах үр ашгийн тооцоолол гаргах, хэмжих, дүгнэх, баталгаажуулах',icon:FaClipboardCheck}
    ]
  return (
    <>
    <h2 className='text-center font-semibold text-2xl pt-10 max-md:text-lg dark:text-white'>Бидний давуу тал</h2>
    <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto mt-3 max-lg:w-24"></div>
    <div className={`grid grid-cols-5 max-lg:grid-cols-3 max-md:grid-cols-2 gap-4 my-10 }`}>
        {data.map((item,index)=>(
            <div key={index} className={`${styles.card} ${index+1===5&&'max-md:col-span-2 max-md:w-1/2 max-md:mx-auto'} rounded border h-[250px] max-md:h-[150px] p-2 text-center max-md:text-[10px] dark:text-white`}>
              {item.icon && (
      <item.icon
        className={`mx-auto text-5xl my-2 max-md:text-[20px] ${
          index % 2 === 0 ? styles.iconEven : styles.iconOdd
        }`}
      />
    )}
            <div>{item.paragraph}</div>
        </div>
        ))}
    </div>
    </>
  ) 
}

export default Reason