import React from 'react'
// import styles from './BPMS.module.css'
import Layout from '../../components/Layout'
import ReactCompareImage from 'react-compare-image'

const BPMS = () => {
    const data  = [
        {item:"Процессын урсгалыг системээр удирдах, гүйцэтгэх, мэдээлэл солилцох"},
        {item:"Процессын алхмыг гүйцэтгэхэд шаардлагатай хэрэгцээт мэдээлэл зааварчилгаа болон формыг холбох, ашиглах"},
        {item:"Шаардлагатай хэрэглэгч бүрээр тухайн процессыг гүйцэтгэх, хянах, удирдах эрхийн тохиргоог тохируулах"},
        {item:"Хэдэн төрлийн процесс, хэдэн төрлийн хувилбараар аль алхмууд дээр явж байгааг шууд харах, хянах"},
        {item:"Процессын явцад алдаа гарвал мэдэгдэх, харуулах, шууд засварлах, урьдчилан сэргийлэх"},
        {item:"Бусад системүүдтэй холбогдох, мэдээлэл солилцох бүрэн боломж"},
        {item:"Хэрэглэхэд ойлгомжтой монгол хэл дээр хөгжүүлсэн интерфэйс"},
        {item:"Ажилтнуудын гар ажиллагааг багасгаж автоматжуулсан шийдэл"}
    ]
  return (
    <>
    <Layout>
        <div className='dark:bg-[rgba(255,255,255,.15)] pb-[100px]'>
            <div className="container pt-[100px]">
                <h2 className='font-semibold text-3xl text-center max-md:text-lg dark:text-[rgba(255,255,255,.9)]'>Процесс удирдлагын систем <span className='text-teal-400'>/BPMSUITE/</span></h2>
                <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto mt-3 max-lg:w-24"></div>
                <div className='grid grid-cols-2 items-center max-md:mt-[30px] max-md:grid-cols-1'>
                <div>
                <p className='text-center text-lg max-md:text-[12px] dark:text-[rgba(255,255,255,.9)] max-md:leading-5'>
                Байгууллагын   дотоод   процессуудыг  <b className='text-[#64CCC5]'> нэгдсэн   нэг   системд   оруулах,
                дижиталжуулж цаасыг багасгах, процессын алхмуудыг удирдах, зохион
                байгуулах, бодит агшинд хянах, тайлан дүгнэлт гаргах</b> боломжтой.
                </p>
                </div>
                {/* <img src="/images/BPMSScroll1.jpg" alt="" className='xl:ml-[60px]'/> */}
                <div className='w-3/5 mx-auto max-md:w-4/5'>
                    <ReactCompareImage leftImage='/images/BPMS1.png' rightImage="/images/BPMS2.png" handleSize={30} sliderLineColor='#000'/>
                </div>
                </div>
                <div>
                    {/* <div className='grid grid-cols-2 gap-[100px] max-md:gap-[20px]'>
                        <div>
                            <h3 className='text-center text-2xl max-md:text-[16px] dark:text-white'>BPMS нэвтрүүлэхээс өмнө</h3>
                            <img src="/images/BPMS1.png" alt="11" />
                        </div>
                        <div>
                        <h3 className='text-center text-2xl max-md:text-[16px] dark:text-white'>BPMS нэвтрүүлсний дараа</h3>
                            <img src="/images/BPMS2.png" alt="22" />
                        </div>
                    </div> */}
                <section>
                    <div className='mb-5 my-[100px]'>
                        <h2 className='font-semibold text-2xl text-center max-md:text-lg dark:text-[rgba(255,255,255,.9)]'>БОЛОМЖИТ ФУНКЦ, ДАВУУ ТАЛ</h2>
                        <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto mt-3 max-lg:w-24"></div>
                    </div>
                    <div className='grid grid-cols-4 max-md:grid-cols-2  gap-x-[40px] max-md:gap-x-[15px] gap-y-10'>
                        {data.map((item,index)=>(
                        <div key={index} className='border text-center p-[20px] max-lg:p-[10px] rounded dark:text-[rgba(255,255,255)] max-lg:text-[10px]'><div className='bg-[#64ccc6] w-[50px] h-[50px] text-center mx-auto rounded-[50%] flex justify-center items-center mt-[-50px] text-white text-xl max-lg:h-[25px] max-lg:w-[25px] max-lg:text-[10px] max-lg:mt-[-25px]'>{index+1}</div> <div className='h-full flex items-center justify-center'>{item.item}</div></div>
                        ))}
                    </div>
                </section>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default BPMS