import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className='flex flex-col items-center justify-center pt-[50px]'>
        <Link to={'/'} className='defaultButton'><img src="/images/LogoLong.png" alt="" /></Link>
        <div className='text-[#64ccc6]'>Not Page Found</div>
        <img src="/images/404.gif" alt="" className='max-h-[700px] max-w-[1000px] max-md:max-h-[300px]'/>
        </div>
  )
}

export default NotFound