import React from 'react'
import styles from './FixedImage.module.css'
import { BiBook,BiCodeBlock,BiWinkSmile } from "react-icons/bi";

const FixedImage = () => {
  return (
    <>
    <div className={styles.img}>
        <div className={`${styles.multiple} flex items-center`}>
        <div className={`container py-10 ${styles.container} max-md:py-0`}>
            <h2 className='text-2xl font-semibold text-center text-white max-md:text-lg'>Боломжоо нэмэгдүүлж, бизнесээ өөрчил</h2>
            <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto my-3 max-lg:w-24"></div>
            <p className='text-center my-3 text-white max-md:text-[10px]'>
            Процессоо   ухаалгаар   удирдан,   өрсөлдөх   чадвараа   нэмэгдүүлж,
              компанийн үйл ажиллагаагаа тасралтгүй сайжруулдаг болоход тань бид
              туслаж зөвхөн танд тохирох програм хангамж, технологийн шийдэл,
              зөвлөх үйлчилгээг санал болгоно.
            </p>
            <div className="grid grid-cols-3 gap-10 max-md:gap-2 mt-[100px] max-xl:mt-[20px] max-lg:mt-[10px]">
                <div className='text-white text-center font-semibold text-md max-xl:text-[12px] max-md:text-[7px] flex flex-col justify-between'><BiBook className='mx-auto text-[#64CCC5] font-thin text-6xl  max-xl:text-3xl'/> УДИРДЛАГЫН БАГ <p>+8 жил туршлагатай</p></div>
                <div className='text-white text-center font-semibold text-md max-xl:text-[12px] max-md:text-[7px] flex flex-col justify-between'><BiCodeBlock className='mx-auto text-[#64CCC5] text-6xl  max-xl:text-3xl'/>ПРОЦЕСС   САЙЖРУУЛАЛТ,<br/> ДАХИН   ЗАГВАРЧЛАЛЫН   БАГ <p>+5 жил туршлагатай</p></div>
                <div className='text-white text-center font-semibold text-md max-xl:text-[12px] max-md:text-[7px] flex flex-col justify-between max-md:gap-2'><BiWinkSmile className='mx-auto text-[#64CCC5] text-6xl  max-xl:text-3xl'/>ПРОЦЕСС   АВТОМАТЖУУЛАЛТ, <br/>  СИСТЕМ   ХӨГЖҮҮЛЭЛТИЙН   БАГ<p>+5 жил туршлагатай</p></div>
            </div>
        </div>
        </div>
    </div>
    </>
  )
}

export default FixedImage