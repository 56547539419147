import React from 'react'
import styles from './Partner.module.css'

const Partner = () => {
  return (
    <>
    <div>
    <h2 className='text-center font-semibold text-2xl mt-[80px] max-md:mt-[10px] max-md:text-lg max-md:text-sm dark:text-[#fff]'>БИДНИЙ ТУРШЛАГА, ХАМТРАГЧ БАЙГУУЛЛАГУУД</h2>
    <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto my-3 max-lg:w-24"></div>
    <div className='flex items-center grid grid-cols-5  my-10 max-lg:grid-cols-3 max-md:grid-cols-2   '>
    {/* <img data-aos="fade-up" src="/images/partner/park.jpg" alt="" className={`${styles.park} dark:filter dark:grayscale`}  /> */}
    <img data-aos="fade-up" src="/images/partner/goodsek.png" alt="" className={`${styles.logo}  dark:filter dark:invert`} />
    <img data-aos="fade-up" src="/images/partner/mandal.png" alt="" className={`${styles.logo} dark:filter dark:grayscale `} />
    <img data-aos="fade-up" src="/images/partner/transco.png" alt="" className={`${styles.logo} dark:filter dark:grayscale `} />
    <img data-aos="fade-up" src="/images/partner/shunhlai.png" alt="" className={`${styles.logo} dark:filter dark:grayscale `} />
    <img data-aos="fade-up" src="/images/partner/elf.png" alt="" className={`${styles.logo} dark:filter dark:grayscale max-md:col-span-2`} />
    </div>
    </div></>
  )
}

export default Partner