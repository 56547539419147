import React, { useEffect } from 'react';
import styles from './Cursor.module.css'

const Cursor = () => {
  useEffect(() => {
    // const cursorDot = document.querySelector("[data-cursor-dot]");
    const cursorOutline = document.querySelector("[data-cursor-outline]");

    const handleMouseMove = (e) => {
      const posX = e.clientX;
      const posY = e.clientY;

      // cursorDot.style.left = `${posX}px`;
      // cursorDot.style.top = `${posY}px`;

      cursorOutline.animate(
        {
          left: `${posX}px`,
          top: `${posY}px`,
        },
        { duration: 10, fill: "forwards" }
      );
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      {/* <div className={`${styles.cursorDot}`} data-cursor-dot></div> */}
      <div className={`${styles.cursorOutline} `} data-cursor-outline></div>
    </>
  );
};

export default Cursor;
