import styles from "./myImage.module.css";

export default function MyImage() { 
  const cards = [
    {
      url: "/images/myImage/relationship.jpg",
      title: "Нээлттэй харилцаа",
      id: 1,
    },
    {
      url: "/images/myImage/teamWork.jpg",
      title: "Мэргэжлийн баг",
      id: 2,
    },
    {
      url: "/images/myImage/team.jpg",
      title: "Хамтын ажиллагаа",
      id: 3,
    },
    {
      url: "/images/myImage/office.jpg",
      title: "Тохилог оффис",
      id: 4,
    },
    {
      url: "/images/myImage/lifeWork.jpg",
      title: "Ажил амьдралын тэнцвэр",
      id: 5,
    },
    {
      url: "/images/myImage/study.jpg",
      title: "Тасралтгүй суралцах",
      id: 6,
    },
  ];
  return (
    <>
      <div data-aos="fade-up" className="text-center font-semibold mb-[100px] max-md:mt-[30px] max-md:mb-[30px] dark:text-[#fff]">
        <h2 className="text-2xl max-md:text-lg">
      Ур чадвартай, шилдэг мэргэжилтнүүд
      </h2>
        <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto my-3 max-lg:w-24 "></div>
        <p className=" font-medium max-w-[700px] mx-auto max-md:text-[10px] max-md:px-5">Шинийг   санаачлагч,  бүтээлч   сэтгэлгээтэй,  мэргэжлийн   өндөр   ур   чадвартай мэргэжилтнүүдээс   бүрдсэн   манай   баг   хамт   олонтой   хамтран   байгууллагаа   дараагийн шатанд гарган тасралтгүй сайжруулаарай.</p>
      </div>

      <div className={styles.body} data-aos="fade-up">
        <div className={styles.marquee}>
          <div className={styles.marquee__group}>
            {/* {cards.map((item)=>(
              <div>
             <img
             src={item.url}
             alt=""
             className={`${item.img} h-[400px] w-[700px] max-lg:h-[300px] max-lg:w-[400px] rounded-lg object-cover bg-center bg-cover`}
           />
           </div>
            ))} */}
            {cards.map((item, index) => (
              <div
                  key={index}
                  className=" h-[400px] w-[700px] max-lg:h-[300px] max-lg:w-[400px] rounded-lg object-cover bg-center bg-cover flex justify-center items-center"
                  style={{ backgroundImage: `url(${item.url})` }}
              >
                  <div className={styles.title}><span className={styles.span}>{item.title}</span></div>
              </div>
            ))}
          </div>

          {/* <div
            aria-hidden="true"
            className={styles.marquee__group}
          >
             {cards.map((item)=>(
              <img
              src={item.url}
              alt=""
              className="w-[400px] h-full rounded-lg object-cover bg-center bg-cover"
            />
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
}
