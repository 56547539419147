import React, { useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next';
import Sponser from '../../components/Sponser/Sponser';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/HomePage/Navbar'
import HomeImage from '../../components/HomePage/HomeImage'
import FixedImage from '../../components/FixedImage/FixedImage';
import Partner from '../../components/Partner/Partner';
import Loader from '../../components/Loader';
import Reason from '../../components/Reason/Reason';
import LayoutImage from '../../components/LayoutImage/LayoutImage';

const Home = () => {
// const {t} = useTranslation("global");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
     {loading ? <Loader /> : 
     <>
     <Navbar/>
    <HomeImage/>
    {/* <div>{t("home.body")}</div>
    <div>{t("header.message")}</div> */}
    <div className='p-[100px] max-md:p-[25px] dark:bg-[rgba(0,0,0,.9)]' id='scrollDown'>
    <Sponser/>
    </div>
    <div className='bg-[rgba(0,0,0,.03)] pb-[100px] max-md:p-[10px] dark:bg-[rgba(0,0,0,.85)]'>
    <div className='container'>
      <LayoutImage/>
      <Reason/>
    </div>
    </div>
    <FixedImage/>
    <div className='dark:bg-[rgba(0,0,0)]'>
    <div className="container py-[100px] max-md:p-[50px]">
    <Partner/>
    </div>
    </div>
    <Footer/>
    </>
    }
    
    </>
  )
}

export default Home