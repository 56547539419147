import React from 'react';
import styles from './LayoutImage.module.css'

const LayoutImage = () => {
    const data =[
        {title:"ТЭЭВЭР ЗУУЧЛАЛ"},
        {title:"АЖ ҮЙЛДВЭРЛЭЛ ТЕХНОЛОГИ"},
        {title:"ЭРЧИМ ХҮЧ"},
        {title:"БАНК САНХҮҮ"},
        {title:"ХАРИЛЦАА ХОЛБОО"},
        {title:"ТӨРИЙН БАЙГУУЛЛАГА"},
        {title:"ШИНЖЛЭХ УХААН"},
        {title:"ДААТГАЛ"},
        {title:"ХУДАЛДАА ҮЙЛЧИЛГЭЭ"}
    ]
  return (
    <>
    <div className='py-[100px]  max-md:py-[30px]'>
    <div className='text-center font-semibold text-2xl text-[#153245] dark:text-[#fff] max-md:text-lg'>BPM-Г ХЭН ХЭРЭГЖҮҮЛДЭГ ВЭ ?</div>
    <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto my-3 max-lg:w-24"></div>
    </div>
    <ul className={`${styles.image_grid} grid grid-cols-3  max-w-[1000px] mx-auto max-sm:gap-1`}>
        {data.map((item, i) => (
          <li key={i} className={`${styles.image_grid__item}`}>
            <div className={`${styles.grid_item}`}>
              <div className={`${styles['grid_item__image']} ${styles['grid_item__image' + (i + 1)]}`}></div>
              <div className={`${styles.grid_item__hover}`}></div>
              <div className={`${styles.grid_item__name} max-md:text-[10px]`}>{item.title}</div>
            </div>
          </li>
        ))}
      </ul>
 {/* <div className='w-[1000px] grid grid-cols-3 gap-2'>
    {Array.from({ length: 9 }).map((_, i) => (
            <div key={i} className={styles.gallery}>
                <div className={styles.galleryChild}>
                    <img src={`/images/layoutImage/${i+1}.jpg`} alt="" className={`${styles.galleryImg} h-[300px] w-full bg-cover bg-center object-cover`} />
              </div>
            </div>
          ))}
    </div> */}

    </>
  )
}

export default LayoutImage