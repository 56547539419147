import React from 'react'
import { useDarkMode } from '../../context/DarkModeContext';
import styles from './DarkToggleButton.module.css'
// import { useState } from 'react';
import { BsFillMoonFill,BsSunFill } from "react-icons/bs";

const DarkToggleButton = () => {
 const { theme, toggleTheme } = useDarkMode();
//  const [darkMode, setDarkMode] = useState(false);


  return (
    <>
    <button  onClick={toggleTheme}>
    {theme === 'dark' ? <BsSunFill className='text-[#ffcc33] text-xl'/> : <BsFillMoonFill className='text-[#94908d] text-xl'/>}
  </button>
  <input type="checkbox" id="toggle_checkbox" className={styles.toggleCheckbox} />
  </>
  )
}

export default DarkToggleButton