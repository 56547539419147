
import React, { createContext, useContext, useEffect, useState } from 'react';

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
    const initialTheme = localStorage.getItem('theme') || 'light';
    const [theme, setTheme] = useState(initialTheme);
    
    const toggleTheme = () => {
      const newTheme = theme === 'dark' ? 'light' : 'dark';
      // Save the selected theme to localStorage
      localStorage.setItem('theme', newTheme);
      setTheme(newTheme);
    };
    
    useEffect(() => {
      document.documentElement.classList.toggle('dark', theme === 'dark');
    }, [theme]);

  return (
    <DarkModeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </DarkModeContext.Provider>
  );
};

// Create a custom hook to use the context
export const useDarkMode = () => {
  return useContext(DarkModeContext);
};


