import React, { useEffect, useState } from 'react';
import { Typewriter } from 'react-simple-typewriter';
import {BsFillArrowDownCircleFill} from 'react-icons/bs';
import styles from "./Navbar.module.css";

export default function Spinner() {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className='threeModel h-[100vh] bg-[#000]'>
      <div className='grid grid-cols-1 items-center lg:grid-cols-2 max-lg:text-center'>
        <div className='col-span-1 flex justify-center items-center'>
          <div className='flex flex-col max-w-md mt-36 md:mt-36 md:mb-64 py-10 max-sm:px-3 max-lg:py-5'>
            <p className='text-[rgba(255,255,255,.9)] my-3 text-xl max-lg:text-xl max-md:text-sm '>
              Харилцагч байгууллагын стратеги зорилт болон
              одоогийн нөхцөл байдлын дүн шинжилгээн дээр
              үндэслэн{' '}
              <span className='text-teal-400 '>
                {' '}
                БИЗНЕС ПРОЦЕСС МЕНЕЖМЕНТИЙГ ХЭРЭГЖҮҮЛЭХ
              </span>
              , процессын алдаа дутагдлыг илрүүлэх,
              сайжруулах, дахин загварчлах,{' '}
              <span className='text-teal-400 '>
                АВТОМАТЖУУЛАХ
              </span>
              , дижитал шилжилтийг дэмжиж ажиллах мэргэжлийн{' '}
              <br />
              <span className='text-orange-500'>
                <Typewriter
                  words={[
                    ' хамт олон . . .',
                    ' байгууллага . . .',
                  ]}
                  loop={100}
                  cursor
                  cursorStyle='_'
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
            </p>
          </div>
        </div>
        <div className='col-span-1 mt-[-100px] max-md:hidden'>
          <img
            src=''
            alt=''
          />
        </div>
      </div>
     <div className='absolute bottom-[20px] w-full text-center'>
      <a href="#scrollDown" className='w-[30px]'  style={{ display: 'inline-block' }}>
        <BsFillArrowDownCircleFill className={`mx-auto text-white h-[30px] w-[30px] max-md:h-[15px]  ${ isSticky ? "hidden" : ""} ${styles.scrollDown}`}/>
        </a>
      </div>
    </div>
  );
}
