import React from "react";
import ReactDOM from "react-dom/client";
import "../src/styles/index.css";
import App from "./App";
import { DarkModeProvider } from "./context/DarkModeContext";
import { BrowserRouter } from "react-router-dom";
import i18next from "i18next";
import global_en from "./translations/en/global.json";
import global_mn from "./translations/mn/global.json";
import { I18nextProvider } from "react-i18next";
import Cursor from "./components/demo/cursor/Cursor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FacebookChat from "./components/FacebookChat";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    mn: {
      global: global_mn,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <DarkModeProvider>
          <App />
          <FacebookChat />
          <ToastContainer />
          <Cursor />
        </DarkModeProvider>
      </I18nextProvider>
    </React.StrictMode>
  </BrowserRouter>
);
