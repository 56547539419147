import React from 'react'
import Layout from '../../components/Layout'

const PDS = () => {
    const data  = [
        {   title:"Процессыг тодорхойлох, стандартжуулах",
            item:"Процесс модел, зураглал гаргах Бизнесийн чадамжуудаа баримтжуулах"},
        {   title:"Процесс сайжруулалт",
            item:"Байгууллагын аль хэсгээс эхлэж сайжруулахаа тодорхойлох Аудит хийх, хяналт хийхэд ашиглах Ажилтнуудын сургалт хөгжилтэй холбох"},
        {   title:"Технологи нэвтрүүлэх",
            item:"Автоматжуулалтыг дэмжиж оновчтой шийдвэр гаргах Бүтэн болон дэд процессуудын мэдээллийг зөв гаргах"},
        {   title:"Харьцуулалт хийх",
            item:"Процессын гүйцэтгэл харьцуулалт хийхэд ашиглах Судалгаа, анализ дүгнэлт гаргахад ашиглах"},
    ]
  return (
    <>
    <Layout>
        <div className='dark:bg-[rgba(255,255,255,.15)] pb-[100px]'>
            <div className="container pt-[100px]">
                <h2 className='font-semibold text-3xl text-center max-md:text-lg dark:text-[rgba(255,255,255,.9)]'>Процессын загварчлалын систем <span className='text-teal-400'>/PDSystem/</span></h2>
                <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto mt-3 max-lg:w-24"></div>
                <div className='grid grid-cols-2 items-center max-md:mt-[30px] max-md:grid-cols-1'>
                    <div>
                        <p className='text-center text-lg max-md:text-[12px] dark:text-[rgba(255,255,255,.9)] max-md:leading-5 max-w-[560px]'>
                            Байгууллагын хэмжээнд <b className='text-[#64CCC5]'> бүтэн процессуудын сан үүсгэх, бүх дүрэм журам, нэгжийн бүтэцтэй холбох, 
                            хэн ч тухайн процессыг гүйцэтгэсэн тодорхой ойлгомжтой нэг стандарттай байх</b> боломжийг бүрдүүлнэ
                        </p>
                    </div>
                    <img src="/images/PDS.png" alt="pds" />
                </div>
                <div className='grid grid-cols-2 gap-10 max-md:grid-cols-1 dark:text-white'>
                    <div>
                        <div className='h-[300px] flex items-center'>
                            <img src="/images/PDS1.png" alt="pds1" className='max-w-[300px] max-md:max-w-[250px] mx-auto'/>
                        </div>
                        <div className='max-w-[500px] mx-auto text-center max-lg:text-[10px]'>Байгууллагын нийт процессуудын жагсаалт, framework-г гаргах, бүтэн процесс бүрээр тодорхойлж эзэнжүүлэх, оролцогч болон гүйцэтгэгч ажилтнуудыг үүрэгжүүлэх, ажилтны гүйцэтгэлийн үнэлгээтэй холбох боломжтой.</div>
                    </div>
                    <div>
                        <div className='h-[300px] flex items-center max-md:h-[200px]'>
                            <img src="/images/PDS2.png" alt="pds1" className='max-w-[300px]  max-md:max-w-[250px] mx-auto'/>
                        </div>
                        <div className='max-w-[500px] mx-auto text-center max-lg:text-[10px]'>Байгууллагын нийт процессууд, түүнийг гүйцэтгэхэд шаардлагатай дүрэм журам, зааварчилгаа, форм, чеклист, видео, линк зэргийг процессын зураглалтай холбон алхмуудын түвшинд харуулах, хэн ч тухайн ажлыг гүйцэтгэсэн тодорхой ойлгомжтой байх систем технологийг нэвтрүүлнэ.</div>
                    </div>
                </div>
                <div>
                <section>
                    <div className='mb-5 my-[100px]'>
                        <h2 className='font-semibold text-2xl text-center max-md:text-lg dark:text-[rgba(255,255,255,.9)]'>БОЛОМЖИТ ФУНКЦ, ДАВУУ ТАЛ</h2>
                        <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto mt-3 max-lg:w-24"></div>
                    </div>
                    <div className='grid grid-cols-4 max-md:grid-cols-2 gap-x-[40px] max-md:gap-x-[15px] gap-y-10'>
                        {data.map((item,index)=>(
                        <div key={index} className='border text-center p-[20px] max-lg:p-[10px] rounded dark:text-[rgba(255,255,255)] max-lg:text-[10px]'><div className='bg-[#64ccc6] w-[50px] h-[50px] text-center mx-auto rounded-[50%] flex justify-center items-center mt-[-50px] text-white text-xl max-lg:h-[25px] max-lg:w-[25px] max-lg:text-[10px] max-lg:mt-[-25px]'>{index+1}</div> 
                        <h3 className='font-bold pt-2 pb-[10px] max-lg:pb-[3px]'>{item.title}</h3>
                         <div className='min-h-[110px] max-lg:min-h-[60px] flex items-center justify-center'>{item.item}</div></div>
                        ))}
                    </div>
                </section>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default PDS