import {
  FacebookProvider,
  CustomChat,
} from "react-facebook";

const FacebookChat = () => {
  return (
    <FacebookProvider
      appId="1839331003168041"
      chatSupport
    >
      <CustomChat
        pageId="106379862448926"
        minimized={true}
      />
    </FacebookProvider>
  );
};

export default FacebookChat;
