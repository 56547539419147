import React from 'react'
import styles from './RPA.module.css'
import Layout from '../../components/Layout'
import ReactCompareImage from 'react-compare-image'

const RPA = () => {
    const dataReason =[
        {item:"Гар ажиллагааг багасгаж бүтээмжийг нэмэгдүүлнэ."},
        {item:"Үйл ажиллагааны зардлыг бууруулна."},
        {item:"Алдааны магадлалыг багасгаж, ажлын ачааллыг бууруулна."},
        {item:"Бүх төрлийн программ систем дээр ажиллана."},
        {item:"Илүү өндөр хурдаар, олон цаг тасралтгүй, чанартай ажиллах чадвартай."}
    ]

  return (
    <>
    <Layout>
    <div className='dark:bg-[rgba(255,255,255,.15)] pt-[50px]'>
        {/* <div className={styles.banner}></div> */}
        <div className='container'>
        <section className='mt-[60px]'>
        <h2 className='font-semibold text-3xl text-center center max-md:text-lg dark:text-[rgba(255,255,255,.9)]'>Роботик процесс автоматжуулалт <span className='text-teal-400'>/RPA/</span></h2>
    <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto mt-3 max-lg:w-24"></div>
    <div className='grid grid-cols-2 gap-3 items-center max-sm:grid-cols-1'>
    <p className='py-3 text-lg dark:text-[rgba(255,255,255,.9)]'>
        <ul className='list-disc mx-3 max-md:text-[12px]'>
        <li>Хүний систем апп дээрх гар ажиллагаануудыг компьютерын програм хангамж ашиглан автоматжуулах технологи.</li>
        <li>Хүнтэй яг адилхан боловч хэд дахин илүү хурдан, алдаагүй хийх робот туслах ажилтан.</li>
        <li>Ажилтнуудыг олон давтамжтай гар ажиллагаанаас чөлөөлж, илүү бодож сэтгэх чухал ажлуудыг хийх цаг боломжийг гаргаж өгөх дижитал шийдэл</li>
        </ul>
        </p>
    {/* <img className='max-h-[400px] mx-auto' src="/images/RPA.jpg" alt="rpa" /> */}
    <div className='w-3/5 mx-auto max-md:w-4/5'>
      <ReactCompareImage leftImage='/images/RPA1.png' rightImage="/images/RPA2.png" handleSize={30} sliderLineColor='#000'/>
      </div>
    </div>
        {/* <div className='grid grid-cols-3 gap-5 max-md:grid-cols-2'>
            <div className=' my-auto font-semibold text-lg text-[rgba(0,0,0,.5)] dark:text-[rgba(255,255,255,.9)] max-md:col-span-2'>Хүний систем апп дээрх гар ажиллагаануудыг компьютерийн програм хангамж ашиглан автоматжуулах технологи</div>
            <div className='col-span-2 grid grid-cols-2 gap-4'>
                <div className='flex items-center gap-[20px] max-md:gap-[10px] max-sm:flex-col'>
                    <img src="/images/bpmSettings.png" alt="" />
                    <div className='max-w-[250px] dark:text-[rgba(255,255,255,.7)]'>Программ, системд нэвтрэх</div>
                </div>
                <div className='flex items-center gap-[20px] max-md:gap-[10px]  max-sm:flex-col'>
                    <img src="/images/bpm3.png" alt="" />
                    <div className='max-w-[250px] dark:text-[rgba(255,255,255,.7)]'>Файл, фолдер зөөх</div>
                </div>
                <div className='flex items-center gap-[20px] max-md:gap-[10px]  max-sm:flex-col max-sm:text-center'>
                    <img src="/images/bpm4.png" alt="" />
                    <div className='max-w-[250px] dark:text-[rgba(255,255,255,.7)]'>Мэдээлэл бүртгэх, унших</div>
                </div>
                <div className='flex items-center gap-[20px] max-md:gap-[10px]  max-sm:flex-col max-sm:text-center'>
                    <img src="/images/bpm5.png" alt="" />
                    <div className='max-w-[250px] dark:text-[rgba(255,255,255,.7)]'>PDF, мэйл, форм, файлаас контэнт татаж авах</div>
                </div>
                <div className='flex items-center gap-[20px] max-md:gap-[10px]  max-sm:flex-col max-sm:text-center'>
                    <img src="/images/bpm6.png" alt="" />
                    <div className='max-w-[250px] dark:text-[rgba(255,255,255,.7)]'>Тооцоолол хийх, тогтоосон дүрмийн дагуу шийдвэр гаргах</div>
                </div>
                <div className='flex items-center gap-[20px] max-md:gap-[10px]  max-sm:flex-col max-sm:text-center'>
                    <img src="/images/bpm7.png" alt="" />
                    <div className='max-w-[250px] dark:text-[rgba(255,255,255,.7)]'>Мэйл, мэйлийн хавсралт нээх, илгээх</div>
                </div>
                <div className='flex items-center gap-[20px] max-md:gap-[10px]  max-sm:flex-col max-sm:text-center'>
                    <img src="/images/bpm3.png" alt="" />
                    <div className='max-w-[250px] dark:text-[rgba(255,255,255,.7)]'>Вебээс мэдээлэл цуглуулах, оруулах</div>
                </div>
                <div className='flex items-center gap-[20px] max-md:gap-[10px]  max-sm:flex-col max-sm:text-center'>
                    <img src="/images/bpm4.png" alt="" />
                    <div className='max-w-[250px] dark:text-[rgba(255,255,255,.7)]'>Систем API-д холбогдох</div>
                </div>
            </div>
        </div> */}
        </section>
        <section className='mt-10'>
            <div className='my-[80px]'>
                <h2 className=' text-2xl max-md:text-lg  text-center font-semibold dark:text-[rgba(255,255,255)]'>Яагаад RPA ашиглах шаардлагатай вэ?</h2>
                <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto mt-3 max-lg:w-24"></div>
            </div>
                <div className='grid grid-cols-5 max-lg:grid-cols-3 max-md:grid-cols-2 gap-x-[40px] max-md:gap-x-[15px] gap-y-10'>
                    {dataReason.map((item,index)=>(
                        <div key={index} className={`border text-center p-[20px] max-md:p-[10px] rounded dark:text-[rgba(255,255,255)]  max-md:text-[10px] ${index+1===5&& 'max-md:col-span-2 max-md:w-1/2 max-md:mx-auto'}`}>
                            <div className='bg-[#64ccc6] max-w-[50px] h-[50px] text-center mx-auto rounded-[50%] flex justify-center items-center mt-[-50px] max-md:h-[25px] max-md:w-[25px] max-md:text-[10px] max-md:mt-[-25px]'>
                                {index+1}
                            </div> 
                            <div className='h-full flex items-center justify-center'>
                                {item.item}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        <img src="/images/RPA3.png" alt="RpaImage" className='mx-auto my-[80px] w-[600px]'/>
        </div>
        <div className='dark:bg-[rgba(255,255,255,.3)] pt-[30px] max-md:pt-[20px]'>
            <div className='mb-[50px]'>
            <h2 className='text-center text-2xl font-semibold max-md:text-lg dark:text-[rgba(255,255,255)]'>Роботын хийж чадах ажлуудын жишээ</h2>
            <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto mt-3 max-lg:w-24"></div>
            </div>
            <div className='container'>
            <div className='grid grid-cols-5 gap-[20px] my-[50px] max-lg:grid-cols-3 max-md:grid-cols-2 max-md:gap-[10px]'>
                <div className={`border rounded flex font-bold max-md:font-medium text-[rgba(255,255,255)] ${styles.card} ${styles.card1}`}><span className={`${styles.span}`}>И-баримт шивэх</span></div>
                <div className={`border rounded flex font-bold max-md:font-medium text-[rgba(255,255,255)] ${styles.card} ${styles.card2}`}><span className={`${styles.span}`}>Масс гүйлгээ хийх</span></div>
                <div className={`border rounded flex font-bold max-md:font-medium text-[rgba(255,255,255)] ${styles.card} ${styles.card3}`}><span className={`${styles.span}`}>Бүх төрлийн тайлан бэлтгэх</span></div>
                <div className={`border rounded flex font-bold max-md:font-medium text-[rgba(255,255,255)] ${styles.card} ${styles.card4}`}><span className={`${styles.span}`}>Нэхэмжлэл бэлтгэх, илгээх</span></div>
                <div className={`border rounded flex font-bold max-md:font-medium text-[rgba(255,255,255)] max-md:col-span-2 max-md:w-1/2 mx-auto ${styles.card} ${styles.card5}`}><span className={`${styles.span} px-[20px]`}>Дансны хуулга шалгах, татах гэх мэт</span></div>
            </div>
            <h2 className='font-bold max-md:text-[10px] text-center dark:text-[rgba(255,255,255,.9)]'>Роботын хийж чадах ажлын жишээ процесс:</h2>
            <img src="/images/RPA4.png" alt="RPA3"  className='w-full'/>
            </div>
        </div>
        <div className='container'>
        <section className={styles.section}>
            <div className={styles.imgBx}>
            <div className={styles.img}> 
            <h2 className='font-semibold text-2xl max-md:text-2xl text-center dark:text-[rgba(255,255,255,.9)]'><span className='text-teal-400'>RPA</span>-г юунд ашиглаж болох вэ?</h2>
            <img src="/images/RPA.jpg" alt="robot" className='max-w-[550px] mx-auto updownAnimation'/>
            </div>        
            </div>
            <div  className={styles.content}>
            <div className='font-semibold text-2xl max-md:text-lg text-center lg:hidden my-5 dark:text-[rgba(255,255,255,.9)]'><span className='text-teal-400'>RPA</span>-г юунд ашиглаж болох вэ?</div>
            <img src="/images/RPA.jpg" alt="robot" className='w-[350px] max-sm:w-[300px] lg:hidden mx-auto updownAnimation'/>
            <div data-aos="fade-up" className=' bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] max-md:text-[10px] rounded text-center text-md max-w-[350px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Их хэмжээний имэйл илгээх, хавсралт файлуудыг хадгалах, боловсруулах
            </div>
            <div data-aos="fade-up" className=' mt-[60px] max-md:mt-[30px] bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] max-md:text-[10px] rounded text-center text-md max-w-[350px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Өдөр тутамд олон давтамжтай хийгддэг үйл ажиллагааг хурдасгах
            </div>
            <div data-aos="fade-up" className=' mt-[60px] max-md:mt-[30px] bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] max-md:text-[10px] rounded text-center text-md max-w-[350px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Өгөгдөл оруулах, анализ дүгнэлт гаргах, тайлагнах
            </div>
            <div data-aos="fade-up" className=' mt-[60px] max-md:mt-[30px] bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] max-md:text-[10px] rounded text-center text-md max-w-[350px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Систем дээрх үйлдлүүдийг хийх мэдээлэл оруулах, хайх, татах
            </div>
            <div data-aos="fade-up" className=' mt-[60px] max-md:mt-[30px] mb-[100px] max-md:mb-[30px] bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] max-md:text-[10px] rounded text-center text-md max-w-[350px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Процессуудын нэрсийн жагсаалтыг үүсгэж, файл хадгалах
            </div>
            </div>
        </section>
        {/* <div className='grid grid-cols-2 gap-3'>
            <img src="/images/RPA1.png" alt="rpa1" className='mx-auto max-h-[600px] '/>
            <img src="/images/RPA2.png" alt="rpa2" className='mx-auto max-h-[600px] '/>
        </div> */}
        {/* <section className={styles.section}>
            <div className={styles.imgBx}>
            <div className={styles.img}> 
            <h2 className='font-semibold text-5xl text-center dark:text-[rgba(255,255,255,.9)]'>НЭВТРҮҮЛЭЛТИЙН ҮЕ ШАТУУД</h2>
            <img src="/images/RPAScroll1.png" alt="" />
            </div>
           
            </div>
            <div  className={`${styles.content}`}>
            <div className='font-semibold text-2xl text-center lg:hidden my-5 dark:text-[rgba(255,255,255,.9)]'>НЭВТРҮҮЛЭЛТИЙН ҮЕ ШАТУУД</div>
            <div data-aos='fade-right' className=' bg-[rgba(0,0,0,.03)] p-5 rounded text-center text-md max-w-[350px] dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                <h2 className='font-semibold text-2xl text-teal-400'>RPA БЭЛТГЭХ</h2>
                Автоматжуулах боломжит процессуудыг тодорхойлж шийдвэрийг гаргах
            </div>
            <div  data-aos='fade-right' className=' mt-[100px] bg-[rgba(0,0,0,.03)] p-5 rounded text-center text-md max-w-[350px] dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                <h2 className='font-semibold text-2xl text-teal-400'>ШИЙДЛИЙГ ЗАГВАРЧЛАХ</h2>
                Процессын орц гарц, дэлгэрэнгүй анализийг хийж, шаардлагатай сайжруулалт, шийдлийг гаргаж загварчлах
            </div>
            <div  data-aos='fade-right' className=' mt-[100px] bg-[rgba(0,0,0,.03)] p-5 rounded text-center text-md max-w-[350px] dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                <h2 className='font-semibold text-2xl text-teal-400'>RPA ХӨГЖҮҮЛЭХ</h2>
                Автоматжуулалт хөгжүүлэх, робот ажиллах процессийг систем дээр зааж өгөх
            </div>
            <div  data-aos='fade-right' className=' mt-[100px] bg-[rgba(0,0,0,.03)] p-5 rounded text-center text-md max-w-[350px] dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                <h2 className='font-semibold text-2xl text-teal-400'>ТЕСТ ХИЙХ</h2>
                Туршилт хийх, алдааг засах
            </div>
            <div  data-aos='fade-right' className=' mt-[100px] bg-[rgba(0,0,0,.03)] p-5 rounded text-center text-md max-w-[350px] dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                <h2 className='font-semibold text-2xl text-teal-400'>НЭВТРҮҮЛЭХ, ЖИГДРҮҮЛЭХ</h2>
                Нэвтрүүлэлтийг хийх, үр дүнг хэмжих
            </div>
            <div  data-aos='fade-right' className=' mt-[100px]  bg-[rgba(0,0,0,.03)] p-5 rounded text-center text-md max-w-[350px] dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                <h2 className='font-semibold text-2xl text-teal-400'>ТАСРАЛТГҮЙ САЙЖРУУЛАХ</h2>
                Өөрчлөлтийг удирдах, хянах
            </div>
            </div>
        </section> */}
        
    </div>
    </div>
    </Layout>
    </>
  )
}

export default RPA