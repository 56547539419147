import styles from "./UsedTechnology.module.css";


export default function UsedTechnology() {
  return (
    <>
    
      <div className={styles.body} >
        <div className={styles.marquee}>
          <div className={styles.marquee__group}>
          {Array.from({ length: 7 }).map((_, i) => (
            <div key={i}>
              <img src={`/images/used/${i}.png`} alt="" className="max-w-[60px] max-md:w-[30px]" />
            </div>
          ))}
          </div>

          <div aria-hidden="true" className={styles.marquee__group}>
          {Array.from({ length: 7 }).map((_, i) => (
            <div key={i}>
              <img src={`/images/used/${i}.png`} alt="" className="max-w-[60px] max-md:w-[30px]" />
            </div>
          ))}
          </div>
        </div>
      </div>
      <div className={styles.body2}>
        <div className={styles.marquee2}>
          <div className={styles.marquee__group2}>
          {Array.from({ length: 7 }).map((_, i) => (
            <div key={i}>
              <img src={`/images/used/${i+7}.png`} alt="" className="max-w-[60px] max-md:w-[30px]" />
            </div>
          ))}
          </div>

          <div aria-hidden="true" className={styles.marquee__group2}>
          {Array.from({ length: 7 }).map((_, i) => (
            <div key={i}>
              <img src={`/images/used/${i+7}.png`} alt="" className="max-w-[60px] max-md:w-[30px]" />
            </div>
          ))}
          </div>
        </div>
      </div>
      <div className={styles.body3}>
        <div className={styles.marquee3}>
          <div className={styles.marquee__group3}>
          {Array.from({ length: 7 }).map((_, i) => (
            <div key={i}>
              <img src={`/images/used/${i+14}.png`} alt="" className="max-w-[60px] max-md:w-[30px]" />
            </div>
          ))}
          </div>

          <div aria-hidden="true" className={styles.marquee__group3}>
          {Array.from({ length: 7 }).map((_, i) => (
            <div key={i}>
              <img src={`/images/used/${i+14}.png`} alt="" className="max-w-[60px] max-md:w-[30px]" />
            </div>
          ))}
          </div>
        </div>
      </div>
    </>
  );
}