import Layout from "../../components/Layout";
import {
  BsMedium,
  BsFacebook,
  BsLinkedin,
  BsFillTelephoneFill,
} from "react-icons/bs";
import styles from "./contact.module.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';
// import { useState } from "react";


export default function ContactUs() {
  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  // const [sanal, setSanal] = useState('');

  // function handleClick() {
  //   // const data = {name,email,phone,sanal}
  //   // console.log("user data",data)
  //   if (name === ''||email === ''||phone === ''||sanal === '') {
  //     toast.error('Mэдээлэл бүрэн оруулна уу?');
  //   }
  //    else {
  //     toast.success('Амжилттай илгээлээ');
  //     setName('')
  //     setEmail('')
  //     setPhone('')
  //     setSanal('')
  //   }
  // }
  return (
    <>
      <Layout>
      <Helmet>
          <title>Холбоо барих</title>
      </Helmet>
        <div className="flex py-[150px] max-lg:py-[50px] grid grid-cols-2 max-md:grid-cols-1 container">
          <div>
            <div className="max-md:grid max-md:grid-cols-2">
            <div className="py-3">
              <h4 className="font-semibold text-2xl max-md:text-lg dark:text-[rgba(255,255,255,.9)]">
                Холбоо барих
              </h4>
              <div className="flex items-center py-[15px] gap-3">
                <BsFillTelephoneFill
                  className={`${styles.contactIcon}`}
                />
                <p className=" dark:text-[rgba(255,255,255,.9)] max-md:text-[10px]">+976 99037128</p>
              </div>
            </div>
            <div className="py-3">
              <h4 className="font-semibold text-2xl max-md:text-lg dark:text-[rgba(255,255,255,.9)]">
                Олон нийтийн сүлжээ
              </h4>
              <div className="flex items-center gap-[40px] max-md:gap-[20px] py-3 text-3xl max-lg:text-xl">
                <Link to={'https://bpsolution.medium.com/'}>
                <BsMedium
                  className={`text-[#000] dark:text-[#fff]`}
                />
                </Link>
                <Link to={'https://www.facebook.com/BPSolution.io'}>
                <BsFacebook
                  className={`text-[#3b5998]`}
                />
                </Link>
                <Link to={'https://www.linkedin.com/company/bpsolution'}>
                <BsLinkedin
                  className={`text-[#0e76a8]`}
                />
                </Link>
              </div>
            </div>
            </div>
            <div className="py-3">
              <h4 className="font-semibold text-2xl max-md:text-lg dark:text-[rgba(255,255,255,.9)]">Хаяг</h4>
              <div className="flex items-center py-[15px] max-md:py-[2px]">
                <p className=" dark:text-[rgba(255,255,255,.9)] max-md:text-[10px]">
                  Улаанбаатар хот, Сүхбаатар
                  дүүрэг, Twin Tower 1, 15 давхар
                </p>
              </div>
            </div>
            <div>
            {/* google map bsn  */}
            </div>
          </div>
          <div>
          {/* <form className="flex flex-col ml-[100px] max-md:ml-0 max-md:mt-[30px] gap-2 dark:text-[rgba(255,255,255,.9)]">
              <h2 className="font-semibold my-[30px] max-md:my-[10px] text-2xl max-lg:text-lg">БИДЭНТЭЙ ХОЛБОГДОХ</h2>
              <div>Нэр</div>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`${styles.input} dark:bg-[rgba(255,255,255,.2)] p-2`}
              />
              <div>Email</div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`${styles.input} dark:bg-[rgba(255,255,255,.2)] p-2`}
              />
              <div>Утасны дугаар</div>
              <input
                type="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className={`${styles.input} dark:bg-[rgba(255,255,255,.2)] p-2`}
              />
              <div>Санал</div>
              <textarea
              cols="30" rows="10"
                value={sanal}
                onChange={(e) => setSanal(e.target.value)}
                className={`${styles.input} dark:bg-[rgba(255,255,255,.2)] p-2`}
              ></textarea>
              <Link to={''} onClick={handleClick} className="w-[150px]">
                <button className="defaultButton w-full bg-[#1AAB96] rounded py-1 mt-3">ИЛГЭЭХ</button>
              </Link>
            </form> */}
             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d838.2788963660579!2d106.90936786968052!3d47.914409897063315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d96924fb8fc3ef9%3A0x1a6ea2240bca5deb!2sTwin%20Tower%201!5e1!3m2!1sen!2smn!4v1698974410609!5m2!1sen!2smn" 
               width="100%"
               height="400px"
               style={{ border: 0 }}
               allowFullScreen
               loading="lazy"
               referrerPolicy="no-referrer-when-downgrade"
               title="Map of City Tower Business Center"
               className="my-3"
              ></iframe>
          </div>
        </div>
      </Layout>
    </>
  );
}
