import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { HiOutlineDocumentText } from "react-icons/hi2";
import { ChevronDownIcon} from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import DarkToggleButton from '../DarkToggleButton/DarkToggleButton'
import headerData from '../../translations/mn/global.json'


const products = [
  { name: "BPM", description: "БИЗНЕС ПРОЦЕСС МЕНЕЖМЕНТ", icon: ChartPieIcon,to:"/bpm" },
  { name: "BPMS", description: "ПРОЦЕСС УДИРДЛАГЫН СИСТЕМ", icon: CursorArrowRaysIcon,to:"/bpms" },
  { name: "RPA", description: "РОБОТИК ПРОЦЕСС АВТОМАТЖУУЛАЛТ", icon: SquaresPlusIcon ,to:"/rpa"},
  { name: "PDS", description: "ПРОЦЕССЫН ЗАГВАРЧЛАЛЫН СИСТЕМ", icon: HiOutlineDocumentText ,to:"/pds"}
]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    
    <header className={`bg-[rgba(255,255,255,.9)] dark:bg-[rgba(0,0,0,.9)]  shadow-md  z-10 fixed w-full`}>
      <div >
      <nav className="mx-auto flex items-center container justify-between px-4 py-2 lg:px-4" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/#" className="-m-1.5 p-1.5 ">
            <span className="sr-only">BPSolution</span>
            <img className="h-12 w-auto max-lg:h-6" src='/images/LogoLong.png' alt="logo" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6 lg:hidden text-teal-400" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex items-center lg:gap-x-12">
          <Link to={'/'} className="text-md font-semibold leading-6 text-white ">
          <div className={`text-[rgba(0,0,0,.9)] dark:text-[#64CCC5]`}>
          {headerData.homePage.navbar.home}
          </div>
          </Link>
          <a href={'/aboutUs'} className="text-md font-semibold leading-6 text-white ">
          <div className={`text-[rgba(0,0,0,.9)] dark:text-[#64CCC5]`}>
          {headerData.homePage.navbar.aboutUs}
          </div>
          </a>
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-white ">
            <div className={`text-[rgba(0,0,0,.9)] dark:text-[#64CCC5]`}>
            {headerData.homePage.navbar.products}
            </div>
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-[rgba(255,255,255,1)] shadow-lg ring-1 ring-gray-900/5 dark:bg-[rgba(0,0,0,.8)]">
                <div className="p-4">
                  {products.map((item,i)=>(
                      <a href={item.to} key={i}>
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-md leading-6 hover:bg-[rgba(0,0,0,.1)] dark:hover:bg-[rgba(255,255,255,.4)]"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg border-2 border-[#64CCC5] ">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-[#64CCC5]" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <div className="block font-semibold text-gray-900 dark:text-[#64CCC5]">
                          {item.name}
                          <span className="absolute inset-0" />
                        </div>
                        <p className="mt-1 text-gray-600 dark:text-[rgba(255,255,255,.9)]">{item.description}</p>
                      </div>
                    </div>
                    </a>

                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <a href={'/contactUs'} className="text-md font-semibold leading-6 text-white ">
          <div className={`text-[rgba(0,0,0,.9)] dark:text-[#64CCC5]`}>
          {headerData.homePage.navbar.contactUs}
          </div>
          </a>
          <div className='flex flex-col items-center'>
            {/* <LanguageToggleButton/> */}
          <DarkToggleButton/>
          </div>

        </Popover.Group>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10 " />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[rgba(255,255,255,.9)] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 dark:bg-[rgba(0,0,0,.9)]">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">BPSolution</span>
              <img
                className="h-15 w-auto"
                src='/images/Logo.png'
                alt="logo2"
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link   
                  to={"/"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-[rgba(255,255,255,.3)] dark:text-[#64CCC5]"
                >
                  {headerData.homePage.navbar.home}
                </Link>
                <a
                  href={'/aboutUs'}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-[rgba(255,255,255,.3)] dark:text-[#64CCC5]"
                >
                    {headerData.homePage.navbar.aboutUs}
                </a>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-[rgba(255,255,255,.3)] dark:text-[#64CCC5]">
                      {headerData.homePage.navbar.products}
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...products].map((item,i) => (
                          // <a href={item.to} key={i}>
                          <Disclosure.Button
                            key={item.to}
                            as="a"
                            href={item.to}
                            className="block rounded-lg py-2 pl-6 pr-3 text-md font-semibold leading-7 text-gray-900 hover:bg-[rgba(255,255,255,.3)] dark:text-[#64CCC5]"
                          >
                            {item.name}
                          </Disclosure.Button>
                          // </a>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <a
                  href={"/contactUs"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-[rgba(255,255,255,.3)] dark:text-[#64CCC5]"
                >
                  {headerData.homePage.navbar.contactUs}
                </a>
                <div className='m-2'>
                  <DarkToggleButton/>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      </div>
    </header>
  )
}
