import React from 'react'
import Layout from '../../components/Layout'
import styles from './BPM.module.css'

const BPM = () => {
  return (
    <>
    <Layout>
    <div className="container py-[100px]">
        <h2 className='font-semibold text-4xl max-md:text-lg text-center center dark:text-[rgba(255,255,255,.9)]'>Бизнес процесс менежмент <span className='text-teal-400'>/BPM/</span></h2>
        <div className="border-b border-3 border-teal-400 rounded-full w-48 mx-auto mt-3 max-lg:w-24"></div>
        <div className='grid grid-cols-2 max-lg:grid-cols-1 py-10'>
            <div className='text-center text-lg max-md:text-[12px] dark:text-[rgba(255,255,255,.9)] my-auto max-md:leading-5'>Байгууллагын <b className='text-[#64ccc6]'> стратегийн зорилт</b>-т  нийцсэн үр дүнд хүрэхийн тулд байгууллагын бизнесийн <b className='text-[#64ccc6]'> гол процессуудыг тодорхойлох, дахин загварчлах, сайжруулах, автоматжуулах, баримтжуулах, стандарт болгох, хэмжих, хянах, тасралтгүй сайжруулах</b> цогц менежментийн арга зам.</div>
            <div><img src="/images/BPM.png" alt="" className='mx-auto max-h-[400px]'/></div>
        </div>
        <div className='grid grid-cols-3 gap-[30px] max-md:gap-[10px] text-center font-semibold max-md:text-[10px]'>
            <a href="#section1" className='border-2 border-[#000] dark:border-teal-400 dark:text-[rgba(255,255,255)] rounded py-2 w-full hover:bg-[#64ccc6]'> Сургалт, зөвлөх үйлчилгээ</a>
            <a href="#section2" className='border-2 border-[#000] dark:border-teal-400 dark:text-white rounded py-2 w-full hover:bg-[#64ccc6]'> Процесс сайжруулах, дахин загварчлах</a>
            <a href="#section3" className='border-2 border-[#000] dark:border-teal-400 dark:text-white rounded py-2 w-full hover:bg-[#64ccc6]'> Ухаалаг процесс автоматжуулалт</a>
        </div>
        <section id='section1' className={styles.section}>
            <div className={styles.imgBx}>
            <div className={styles.img}> 
            <h2 className='font-semibold text-2xl text-center dark:text-[rgba(255,255,255,.9)]'>Сургалт, зөвлөх үйлчилгээ</h2>
            <img src="/images/BPMScroll3.png" alt="" className={`w-[450px] py-10 mx-auto updownAnimation`}/>
            </div>     
            </div>
            <div  className={styles.content}>
            <div className='font-semibold text-2xl max-md:text-lg text-center lg:hidden mt-5 dark:text-[rgba(255,255,255,.9)]'>Сургалт, зөвлөх үйлчилгээ</div>
            <img src="/images/BPMScroll3.png" alt="" className={`w-[350px] py-10 mx-auto lg:hidden updownAnimation`}/>
            <div data-aos="fade-right" className=' bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] rounded text-center text-md max-md:text-[10px] max-w-[600px]  max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
            Байгууллагын   одоогийн   нөхцөл   байдал,   хэрэгцээ   шаардлагад
                тулгуурласан   хөтөлбөрийг   зөвхөн   танд   зориулан   боловсруулж
                мэргэжлийн өндөр түвшинд зөвлөх үйлчилгээ үзүүлэх, аргачлал tools-р
                хангана.
            </div>
            <div data-aos="fade-right" className={`mt-[60px] max-md:mt-[30px] bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] rounded text-center text-md max-md:text-[10px] max-w-[600px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]`}>
            Байгууллагын удирдлагуудад болон Ажилтнуудад чиглэсэн тусгайлсан
                сургалтын хөтөлбөрүүдийг боловсруулж бодит кэйс, багийн ажиллагаан
                дээр тулгуурлан сургаж чиглүүлнэ.
            </div>
            </div>
        </section>
        <section id='section2' className={styles.section}>
            <div className={styles.imgBx}>
            <div className={styles.img}> 
            <h2 className='font-semibold text-2xl text-center dark:text-[rgba(255,255,255,.9)]'>Процесс сайжруулах, дахин загварчлах</h2>
            <img src="/images/BPMScroll1.png" alt="" className='w-[400px] py-10 mx-auto updownAnimation'/>
            </div>     
            </div>
            <div  className={styles.content}>
            <div className='font-semibold text-2xl max-md:text-lg text-center lg:hidden mt-5 dark:text-[rgba(255,255,255,.9)]'>Процесс сайжруулах, дахин загварчлах</div>
            <img src="/images/BPMScroll1.png" alt="" className={`w-[350px] max-sm:w-[300px] py-10 mx-auto lg:hidden updownAnimation`}/>
            <div data-aos="fade-up" className=' bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] rounded text-center text-md max-md:text-[10px] max-w-[600px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Байгууллагын одоогийн нөхцөл байдлын анализ, дүн шинжилгээ, Процесс аудит хийнэ
            </div>
            <div data-aos="fade-up" className={` mt-[60px] max-md:mt-[30px] bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] rounded text-center text-md max-md:text-[10px] max-w-[600px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]`}>
                Байгууллагын процессын санг үүсгэж өгнө, дүрэм, журмыг боловсруулна
            </div>
            <div data-aos="fade-up" className=' mt-[60px] max-md:mt-[30px]  bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] rounded text-center text-md max-md:text-[10px] max-w-[600px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Процессын алдаа дутагдлыг илрүүлнэ, арилгана
            </div>
            <div data-aos="fade-up" className=' mt-[60px] max-md:mt-[30px]  bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] rounded text-center text-md max-md:text-[10px] max-w-[600px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Бизнесийн хэрэгцээ шаардлага дээр үндэслэсэн гаднаас дотогшоо чиглэлтэй процесс сайжруулалт, дахин загварчлалыг хийнэ
            </div>
            <div data-aos="fade-up" className=' mt-[60px] max-md:mt-[30px]  bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] rounded text-center text-md max-md:text-[10px] max-w-[600px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Бодит туршилт, нэвтрүүлэлтийг гүйцэтгэх, үр ашгийг тооцон хүлээлгэн өгнө
            </div>
            </div>
        </section>
        <section id='section3' className={styles.section}>
            <div className={styles.imgBx}>
            <div className={styles.img}> 
            <h2 className='font-semibold text-2xl text-center dark:text-[rgba(255,255,255,.9)]'>Ухаалаг процесс автоматжуулалт</h2>
            <img src="/images/BPMScroll2.png" alt="" className='w-[400px] py-10 mx-auto updownAnimation'/>
            </div>
            </div>
            <div  className={styles.content}>
            <div className='font-semibold text-2xl max-md:text-lg text-center lg:hidden mt-5 dark:text-[rgba(255,255,255,.9)]'>Ухаалаг процесс автоматжуулалт</div>
            <img src="/images/BPMScroll2.png" alt="" className={`w-[350px] max-sm:w-[300px] py-10 mx-auto lg:hidden updownAnimation`}/>
            <div data-aos="fade-right" className=' bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] rounded text-center text-md max-md:text-[10px] max-w-[600px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Системдээ тохируулан ажиллах биш, байгууллагын процесст тохирсон систем  технологийн шийдлийг гаргаж зөвхөн танай байгууллагад зориулан хөгжүүлэлтийг  хийнэ
            </div>
            <div data-aos="fade-right" className={` mt-[60px] max-md:mt-[30px] bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] rounded text-center text-md max-md:text-[10px] max-w-[600px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]`}>
                Байгууллагын дотооддоо ашигладаг систем аппликэйшнуудтай интеграци хийж хэрэглэхэд хялбар, ухаалаг процесс автоматжуулалтыг нэвтрүүлнэ
            </div>
            <div data-aos="fade-right" className=' mt-[60px] max-md:mt-[30px]  bg-[rgba(0,0,0,.03)] p-[40px] max-md:p-[20px] rounded text-center text-md max-md:text-[10px] max-w-[600px] max-md:mx-5 dark:bg-[rgba(255,255,255,.2)] dark:text-[rgba(255,255,255,.9)]'>
                Бизнес процессуудаа дэвшилтэт технологи ашиглан удирдах, хянах, тасралтгүй сайжирдаг байх боломжийг бүрдүүлнэ
            </div>
            </div>
        </section>
    </div>
    </Layout> 
    </>
  )
}

export default BPM