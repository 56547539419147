import '../src/styles/App.css';
import '../src/styles/index.css';
import { Routes, Route } from 'react-router-dom';
import ContactUs from './pages/contactUs/ContactUs';
import NotFound from './components/NotFound';
import AboutUs from './pages/AboutUs';
import Home from './pages/Home/Home';
import BPM from './pages/BPM/BPM';
import RPA from './pages/RPA/RPA';
import { Helmet } from 'react-helmet';
import BPMS from './pages/BPMS/BPMS';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import PDS from './pages/PDS/PDS';

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Helmet>
        <title>BPSolution</title>
        <meta
          name='description'
          content='Helmet application'
        />
        <meta property='og:url' content='https://bpsolution.io/' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='BPSolution' />
        <meta property='og:image' content='/images/facebookImage2.png' />
      </Helmet>
      <Routes>
        <Route
          path='/'
          element={<Home />}
        />
        <Route
          path='/contactUs'
          element={<ContactUs />}
        />
        <Route
          path='/aboutUs'
          element={<AboutUs />}
        />
        <Route
          path='/bpm'
          element={<BPM />}
        />
        <Route
          path='/bpms'
          element={<BPMS />}
        />
        <Route
          path='/rpa'
          element={<RPA />}
        />
        <Route
          path='/pds'
          element={<PDS />}
        />
        <Route
          path='*'
          element={<NotFound />}
        />
      </Routes>
    </>
  );
}

export default App;
